import React, { Component } from "react";
import { Row, Col } from "antd";
import { get, set } from "lodash";
import { connect } from "react-redux";

//Constants
import { BREADCRUMBS, ROUTES } from "../../../../constants";

// Custom Components
import InputPrimary from "../../../../components/InputPrimary";
import ImageUpload from "../../../../components/ImageUpload";
import ButtonPrimary from "../../../../components/buttons/ButtonPrimary";
import SelectInput from "../../../../components/SelectInput";
import PrimaryDatePicker from "../../../../components/PrimaryDatePicker";
import PrimaryBreadcrumb from "../../../../components/PrimaryBreadcrumb";
import Loader from "../../../../components/PrimaryLoader";
import ErrorModal from "../../../../components/modal/ErrorModal";
import SuccessModal from "../../../../components/modal/SuccessModal";
import PhoneNoComponent from "../../../../components/PhoneNoComponent";

// images
import userImage from "../../../../assets/user-grey.svg";

// Style
import "./styles.scss";
import { PageTitle } from "../../../../components/PageTitle";

//Services
import * as DM from "../../dataManager";
import * as RDM from "../../../../common/dataManager";
import * as PDM from "../../../Package/dataManager";

//Helpers
import {
  breakTimestampForAPI,
  getMaxLengthForCountryCode,
  convertCase,
  returnMomentObject,
} from "../../../../helpers";
import { validate, parseError } from "../../parser";
import { ENUMS } from "../../Constants";
import { packages } from "../../../../constants/packages";

class LearnerNew extends Component {
  constructor(props) {
    super();
    this.editableID = null;
    this.state = {
      isLoader: false,
      errorModal: null,
      succModal: null,
      errObj: null,
      learner: null,
      role_id: "",
      roles: [],
      packages: [],
      type: ENUMS.INDIVIDUAL,
      packageName: null
    };
  }

  async componentDidMount() {
    this.editableID = this.props.match.params.id;
    this.getAllPackagesData();
    if (this.editableID) {
      this.getAllRoles();
      await this.getSpecificLearnerDetials();
    }
    this.getRoleId();
  }

  getAllPackagesData = () => {
    this._startLoader();
    const queryObject = {
      limit: 100,
      page: 1,
    };
    PDM.getAllPackagesService(queryObject)
      .then((res) => {
        this.setState({ packages: res.data.results });
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  getRoleId = () => {
    this._startLoader();
    RDM.getAllRolesService({ type: "STUDENT" })
      .then((res) => {
        this._stopLoader();
        this.setState({ role_id: get(res, "data.results[0].id", "") });
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  getAllRoles = () => {
    this._startLoader();
    RDM.getAllRolesService()
      .then((res) => {
        this._stopLoader();
        const allowedTypes = ["STUDENT", "MENTOR", "SUPER_MENTOR"];
        const roles = res?.data?.results?.filter((role) => allowedTypes.includes(role.type)) || [];
        this.setState({ roles });
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  getSpecificLearnerDetials = () => {
    this._startLoader();
    DM.getUserService(this.editableID)
      .then((res) => {
        res?.data?.meta?.location[0]?.longitude ? this.setState({ type: ENUMS.LIBRARY, learner: res.data, packageName: res?.data?.userpackages?.packages?.name }) : this.setState({ type: ENUMS.INDIVIDUAL, learner: res.data, packageName: res?.data?.userpackages?.packages?.name })
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  setStateValue = (value, field) => {
    let state = this.state;
    if (!state.learner) state.learner = {};
    if (field.includes('.')) {
      set(state.learner, field, value);
    } else {
      state.learner[`${field}`] = value;
    }
    this.setState(state);
  };

  handleSaveButton = async () => {
    const { learner, type, packageName } = this.state;
    const checkValidate = validate(learner || {}, this.editableID, type);
    if (checkValidate) {
      this.setState({
        errObj: checkValidate,
        errorModal: { title: "Validation Error Occured" },
      });
      return;
    }
    let { country_code } = learner;
    if (country_code) {
      if (country_code.includes("(")) {
        country_code = country_code.split("(")[1];
        country_code = country_code.substring(0, country_code.length - 1);
      }
      if (!learner.phone || !learner.phone.includes("-"))
        learner.phone = `${country_code}-${learner.phone}`;
    }

    if (type === ENUMS.LIBRARY && packageName === packages.FREE_PACKAGE) {
      this.setState({
        errorModal: { description: 'Cannot assign library account to free package!' },
      });
      return;
    }

    this._startLoader();
    learner.user_roles = [this.state.role_id];
    if (learner.user_interest) delete learner.user_interest;
    if (this.editableID) {
      DM.editUserService(this.editableID, learner)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: {
              title: "Success",
              description: "Learner edited successfully.",
            },
          });
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") },
          });
        });
    } else {
      DM.createUserService(learner)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: {
              title: "Success",
              description: "Learner created successfully.",
            },
          });
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") },
          });
        });
    }
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  successToggleModal = () => {
    this.setState(
      {
        succModal: this.state.succModal ? null : {},
      },
      () => {
        if (!this.state.succModal) this.props.history.push(ROUTES.LEARNERS);
      },
    );
  };

  makeOptionChild = (entity) => {
    let entityChild = [];
    const state = this.state;
    if (state[`${entity}`] && Array.isArray(state[`${entity}`])) {
      this.state[`${entity}`].map((val) => {
        entityChild.push(
          <option value={val.id} key={val.id}>
            {val.name}
          </option>,
        );
        return null;
      });
    }
    return entityChild;
  };

  render() {
    const { learner, isLoader, errorModal, errObj, succModal, role_id, type } = this.state;
    const defaultImageUrl = get(learner, "media.path", null);

    if (learner) {
      if (
        !learner.country_code &&
        learner.phone &&
        learner.phone.includes("-")
      ) {
        learner.country_code = learner.phone.split("-")[0];
      }
    }

    const packgeChild = this.makeOptionChild("packages");
    const roleChild = this.makeOptionChild("roles");

    return (
      <>
        <PageTitle title="Learners" />
        <div className="new-learners-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.LEARNERS,
                  {
                    text: this.editableID ? "Edit Learner" : "Add new learners",
                  },
                ]}
              />
              <h4 className="title4">
                {this.editableID ? "Edit Learner" : "Add new learners"}
              </h4>
            </div>
          </div>

          <div className="form-section">
            <p className="title">Basic Details</p>

            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="input-item">
                  <p className="lable">Profile photo</p>
                  <ImageUpload
                    defaultImage={true}
                    subText={
                      defaultImageUrl
                        ? "Change photo"
                        : "Pick a photo from your computer"
                    }
                    text={
                      defaultImageUrl
                        ? get(learner, "media.file_name")
                        : "(Max file size: 10MB)"
                    }
                    defaultImageUrl={defaultImageUrl || userImage}
                    onChange={(file) => {
                      let originalFileObject = file.originFileObj;
                      this.setStateValue(originalFileObject, "avatar");
                      this.setStateValue(file, "rawFile");
                    }}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <Row gutter={[20]}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">
                        First Name <span className="required-text">*</span>
                      </p>
                      <InputPrimary
                        value={get(learner, "first_name")}
                        placeholder="John"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "first_name");
                        }}
                        errorText={get(errObj, "first_name")}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">
                        Last Name <span className="required-text">*</span>
                      </p>
                      <InputPrimary
                        placeholder="Dohe"
                        value={get(learner, "last_name")}
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "last_name");
                        }}
                        errorText={get(errObj, "last_name")}
                      />
                    </div>
                  </Col>
                </Row>

                <Row gutter={[20]}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Phone number</p>
                      <PhoneNoComponent
                        //  children={children}
                        codeVal={get(learner, "country_code")}
                        phoneVal={get(learner, "phone")}
                        onChangeCode={(val) => {
                          this.setStateValue(val, "country_code");
                        }}
                        onChangePhone={(e) => {
                          this.setStateValue(e.target.value, "phone");
                        }}
                        maxLength={getMaxLengthForCountryCode(
                          get(learner, "country_code", null),
                        )}
                        errorText={get(errObj, "phone")}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">
                        Email address <span className="required-text">*</span>
                      </p>
                      <InputPrimary
                        disabled={this.editableID ? true : false}
                        value={get(learner, "email")}
                        type="email"
                        placeholder="johndohe@gmail.com"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "email");
                        }}
                        errorText={get(errObj, "email")}
                      />
                    </div>
                  </Col>
                </Row>

                <Row gutter={[20]}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Gender</p>
                      <SelectInput
                        selectInputStyle="select-absolute shift-down-select"
                        value={convertCase(get(learner, "gender")) || undefined}
                        placeholder="Select Gender"
                        options={["Male", "Female", "Other"]}
                        onChange={(val) => {
                          this.setStateValue(val.toUpperCase(), "gender");
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Date of birth</p>
                      <PrimaryDatePicker
                        value={
                          get(learner, "dob")
                            ? returnMomentObject(get(learner, "dob"))
                            : ""
                        }
                        onChange={(val) => {
                          this.setStateValue(
                            get(val, "_d", null)
                              ? breakTimestampForAPI(val._d).date
                              : "",
                            "dob",
                          );
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                {!this.editableID && (
                  <Row gutter={[20]}>
                    <Col xs={24} sm={24} md={12}>
                      <div className="input-item">
                        <p className="lable">
                          Password <span className="required-text">*</span>
                        </p>
                        <InputPrimary
                          type="password"
                          placeholder="password"
                          onChange={(e) => {
                            this.setStateValue(e.target.value, "password");
                          }}
                          errorText={get(errObj, "password")}
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <div className="input-item">
                        <p className="lable">
                          Confirm Password{" "}
                          <span className="required-text">*</span>
                        </p>
                        <InputPrimary
                          type="password"
                          placeholder="Confirm password"
                          onChange={(e) => {
                            this.setStateValue(
                              e.target.value,
                              "confirmPassword",
                            );
                          }}
                          errorText={get(errObj, "confirmPassword")}
                        />
                      </div>
                    </Col>
                  </Row>
                )}

                <Row gutter={[20]}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Add Package</p>
                      <SelectInput
                        selectInputStyle="select-absolute shift-down-select"
                        value={get(
                          learner,
                          "package_id",
                          get(learner, "userpackages.packages.id"),
                        )}
                        child={packgeChild}
                        placeholder="Select Package"
                        onChange={(value) => {
                          const selectedPackage = this.state.packages.find(pkg => pkg.id === value);
                          this.setStateValue(value, "package_id");
                          this.setState({ packageName: selectedPackage ? selectedPackage?.name : '' });
                        }}
                        errorText={get(errObj, "package_id")}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Organization</p>
                      <InputPrimary
                        value={get(learner, "organization")}
                        type="text"
                        placeholder="Organization"
                        maxLength={255}
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "organization");
                        }}
                        errorText={get(errObj, "organization")}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Current School</p>
                      <InputPrimary
                        value={get(learner, "current_school_uni")}
                        type="text"
                        placeholder="Current School"
                        maxLength={255}
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "current_school_uni");
                        }}
                        errorText={get(errObj, "current_school_uni")}
                      />
                    </div>
                  </Col>
                  {this.editableID &&
                    (<Col xs={24} sm={24} md={12}>
                      <div className="input-item">
                        <p className="lable">User Role</p>
                        <SelectInput
                          value={role_id}
                          child={roleChild}
                          placeholder="Select Role"
                          onChange={(value) => {
                            this.setState({ role_id: value });
                          }}
                          errorText={get(errObj, "role_id")}
                        />
                      </div>
                    </Col>)}
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Select the account type<span className="required-text">*</span></p>
                      <div className="radio-button">
                        <div className="radio-item">
                          <input
                            type="radio"
                            name="student"
                            id="individual"
                            value={type}
                            checked={type === ENUMS.INDIVIDUAL}
                            onChange={(value) => {
                              this.setState({ type: ENUMS.INDIVIDUAL },
                                () => {
                                  this.setStateValue(null, "meta.location[0].latitude")
                                  this.setStateValue(null, "meta.location[0].longitude")
                                  this.setStateValue(null, "max_login_sessions")
                                  this.setStateValue(null, "meta.distance")
                                  this.setStateValue(ENUMS.INDIVIDUAL, 'account_type')
                                }
                              );
                            }}
                          />
                          <label for='individual' className="lable">Individual</label>
                        </div>
                        <div className="radio-item">
                          <input
                            type="radio"
                            name="student"
                            id="library"
                            value={type}
                            checked={type === ENUMS.LIBRARY}
                            onChange={(value) => {
                              this.setState({ type: ENUMS.LIBRARY }, () => {
                                this.setStateValue(ENUMS.LIBRARY, 'account_type')
                              });
                            }}
                          />
                          <label for='library' className="lable">Library</label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                {type === 'library' && (<Row gutter={20} style={{ marginBottom: '20px' }}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">
                        Latitude <span className="required-text">*</span>
                      </p>
                      <InputPrimary
                        placeholder="Enter the latitude"
                        value={get(learner, "meta.location[0].latitude")}
                        type="number"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "meta.location[0].latitude");
                        }}
                        errorText={get(errObj, "latitude")}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">
                        Longitude <span className="required-text">*</span>
                      </p>
                      <InputPrimary
                        placeholder="Enter the longitude"
                        value={get(learner, "meta.location[0].longitude")}
                        type="number"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "meta.location[0].longitude");
                        }}
                        errorText={get(errObj, "longitude")}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">
                        Distance in Km <span className="required-text">*</span>
                      </p>
                      <InputPrimary
                        placeholder="Enter the distance in Kms"
                        value={get(learner, "meta.distance")}
                        type="number"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "meta.distance");
                        }}
                        errorText={get(errObj, "distance")}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">
                        Maximum login sessions <span className="required-text">*</span>
                      </p>
                      <InputPrimary
                        placeholder="Enter the maximum sessions allowed"
                        value={get(learner, "max_login_sessions")}
                        type="number"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "max_login_sessions");
                        }}
                        errorText={get(errObj, "max_login_sessions")}
                      />
                    </div>
                  </Col>
                </Row>)}
              </Col>
            </Row>

            <div className="mt--40 text-right">
              <ButtonPrimary
                btnText="Save"
                href="#"
                onClick={this.handleSaveButton}
              />
            </div>

            {isLoader && <Loader />}
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {})(LearnerNew);
